<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>销售管理</el-breadcrumb-item>
      <el-breadcrumb-item>销售出库单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-form  :model="addWarehouseenter" :rules="addWarehouseenterRules"
                ref="addWarehouseenterRef" label-width="100px">
        <el-row>
          <el-col>
            <el-form-item label="仓库" prop="warehouselist">
              <el-cascader
                  :options="TreeList" v-model="addWarehouseenter.warehouselist"
                  :props="Treeprops" @change="getcheckList" class="width250"
                  clearable></el-cascader>
            </el-form-item>
          </el-col>

        </el-row>
        <el-form-item label-width="0">
          <el-table :data="addWarehouseenter.goodslist" border stripe  >
            <el-table-column type="index"></el-table-column>
            <el-table-column label="商品编码" prop="ga_code" width="100px"></el-table-column>
            <el-table-column label="商品名称" prop="g_name"></el-table-column>
            <el-table-column label="商品规格" width="300px">
              <template slot-scope="scope" >
                <div>{{scope.row.ga_name}}</div>
                <el-select v-model="scope.row.gad_id" placeholder="请选择日期" v-if="scope.row.goodsattrdate.length!=0"
                           clearable class="p_purchase_ids">
                  <el-option
                      v-for="item in scope.row.goodsattrdate"
                      :key="item.gad_id"
                      :label="`${item.gad_start_date}~${item.gad_end_date}`"
                      :value="item.gad_id">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
<!--            <el-table-column label="开始时间~结束时间" width="300px">-->
<!--              <template slot-scope="scope" v-if="scope.row.goodsattrdate.length!=0">-->
<!--                <el-select v-model="scope.row.gad_id" placeholder="请选择"-->
<!--                           clearable class="p_purchase_ids">-->
<!--                  <el-option-->
<!--                      v-for="item in scope.row.goodsattrdate"-->
<!--                      :key="item.gad_id"-->
<!--                      :label="`${item.gad_start_date}~${item.gad_end_date}`"-->
<!--                      :value="item.gad_id">-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </template>-->
<!--            </el-table-column>-->
            <el-table-column label="单位" prop="gu_name" width="50px"></el-table-column>
            <el-table-column label="销售数量" prop="si_number"></el-table-column>
            <el-table-column label="已出库数量" prop="si_warhouse_number"></el-table-column>
            <el-table-column label="出库数量" prop="si_number">
              <template slot-scope="scope">
                <el-input v-model="scope.row.woi_number" @blur="number(scope.row)"></el-input>
<!--                {{scope.row.woi_number=scope.row.si_number}}-->
              </template>
            </el-table-column>
<!--            <el-table-column label="删除">-->
<!--              <template slot-scope="scope">-->
<!--                <el-button type="danger" @click="delGoodsList(scope.row.ga_id)">删除</el-button>-->
<!--              </template>-->
<!--            </el-table-column>-->
          </el-table>
        </el-form-item>
        <el-form-item label="经办人：" prop="wo_agent">
          <el-select v-model="addWarehouseenter.wo_agent" placeholder="请选择"
                     clearable class="width250">
            <el-option
                v-for="item in userlist"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户：" prop="we_agent">
          <el-select v-model="addWarehouseenter.c_id" placeholder="请选择"
                     clearable class="width250" filterable>
            <el-option
                v-for="item in supplierList"
                :key="item.c_id"
                :label="item.c_name"
                :value="item.c_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出库时间：" prop="wo_date">
          <el-date-picker v-model="addWarehouseenter.wo_date" class="width250"
                          type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="出库备注：" prop="wo_remark">
          <el-input v-model="addWarehouseenter.wo_remark" class="width250"></el-input>
        </el-form-item>

        <!--        提交-->
        <div style="float: right; padding-bottom:20px; ">
          <el-button @click="addgo">取消</el-button>

          <el-button type="primary" @click="addupPurchase" :loading="addloading">确定</el-button>
        </div>
      </el-form>

    </el-card>


  </div>
</template>
<script>
export default {
  data() {
    return {
      addloading:false,
      querInfo: {
        page: 1,
        limit: 10,
        's_id':''
      },
      total:0,
      //添加表单的form表单
      addWarehouseenter:{
        wo_type:1,
        warehouselist:[],
        goodslist:[],
        s_id:this.$route.query.editid,
        wo_date: this.$store.state.daydate,
        wo_agent:this.$store.state.m_id
      },
      Goodslist:[],
      //员工列表
      userlist:[],
      //供应商列表
      TreeList:[],
      //客户列表
      supplierList:[],
      Treeprops:{
        // multiple: true,
        // checkStrictly: true,
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'w_id',
        label: 'w_name',
        children: 'children'},
        id:{'s_id':''},
      //控制添加商品的显示或隐藏
      addGoodsDialogVisible:false,
      checkBoxData: [],
      //
      adddisabled:true,
      addWarehouseenterRules: {
        warehouselist: [
          {required: true, message: '请选择仓库', trigger: 'change'}
        ],
        wo_agent:[
          {required: true, message: '请选择经办人', trigger: 'change'}
        ],
        wo_date:[
          {required: true, message: '请选择出库时间', trigger: 'change'}
        ],
        // wo_remark:[
        //   {required: true, message: '请输入出库备注', trigger: 'blur'}
        // ]
      }

    }
  },
  created() {
    this.showSetTreeListDialog()
    this.getuserList()
    this.getsupplierList()
    this.getGoodsList()
  },
  methods: {
    //展示仓库的对话框
    async showSetTreeListDialog() {
      //获取所有权限的数据
      const {data: res} = await this.$http.get('warehouse/getTreeList')
      if (res.code != 200) return this.$message.error(res.msg)
      this.TreeList = res.data
    },
    //获取商品数据
    async getGoodsList(id){
      const {data: res} = await this.$http.get(`sale/find/${this.$route.query.editid}`)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.addWarehouseenter.goodslist = res.data.goodslist
      // this.Goodslist=res.data.data
      // this.total=res.data.total
      // setTimeout(() => {
      //   this.pickUp() //需要默认勾选的数据
      // }, 10)
    },
    //获取客户列表
    async getsupplierList() {
      const {data: res} = await this.$http.get('customer/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.supplierList = res.data
    },
    //获取采购员
    async getuserList() {
      const {data: res} = await this.$http.get('member/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.userlist=res.data
    },

    //供应商选项框发生变化时的回调函数
    getcheckList(){
      if (this.addWarehouseenter.warehouselist == []){
        this.addWarehouseenter.goodslist=[]
        this.adddisabled=true
        return true
      }else{
        this.addWarehouseenter.w_id=this.addWarehouseenter.warehouselist[0]
        this.addWarehouseenter.wp_id=this.addWarehouseenter.warehouselist[1]
        this.querInfo.w_id=this.addWarehouseenter.warehouselist[0]
        this.querInfo.wp_id=this.addWarehouseenter.warehouselist[1]

        // this.id.s_id=this.ss_id
        this.adddisabled=false
      }
    },
    handleSelectionChange (selection) {
      console.log(selection)
    },

    //提交form表单
    async addupPurchase(){
      this.$refs.addWarehouseenterRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        const {data: res} = await this.$http.post('warehouseout/add', this.addWarehouseenter)
        this.addloading=false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.$router.go(-1)
      })
    },
    addgo(){
      this.$router.go(-1)
    },
    number(row){
      if (isNaN(row.woi_number)==true){
        row.woi_number=0
      }else if(row.woi_number<=0){
        row.woi_number=0
      }
    }

  },


}
</script>
<style lang="less" scoped>
.p_price,.p_purchase_id,.p_date,.p_remark,.p_other_price{
  width: 300px;
}
.el-cascader{
  width: 300px;
}
.el-select{
  width: 300px;
}
.p_purchase_ids{
  width: 250px;
}
.width250{
  width: 250px;
}
</style>
